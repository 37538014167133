// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/fe-user-pc_s1Sf/node_modules/.pnpm/@umijs+runtime@3.5.20_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Global/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/500",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__500__index' */'@/pages/500/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/Incoming",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Incoming__index' */'@/pages/Incoming/index.tsx'), loading: LoadingComponent}),
        "title": "线上进件"
      },
      {
        "path": "/account",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__index' */'@/pages/account/index.tsx'), loading: LoadingComponent}),
        "title": "我的账号"
      },
      {
        "path": "/baseinfo",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__baseinfo__index' */'@/pages/baseinfo/index.tsx'), loading: LoadingComponent}),
        "title": "基础信息"
      },
      {
        "path": "/bind/const",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__const' */'@/pages/bind/const.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/bind/email",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__email__index' */'@/pages/bind/email/index.tsx'), loading: LoadingComponent}),
        "title": "邮箱绑定"
      },
      {
        "path": "/bind/idcard",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__idcard__index' */'@/pages/bind/idcard/index.tsx'), loading: LoadingComponent}),
        "title": "实名认证"
      },
      {
        "path": "/bind/idcard/policy",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__idcard__policy__index' */'@/pages/bind/idcard/policy/index.tsx'), loading: LoadingComponent}),
        "title": "实名认证协议"
      },
      {
        "path": "/bind/idcard/question/const",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__idcard__question__const' */'@/pages/bind/idcard/question/const.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/bind/idcard/question",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__idcard__question__index' */'@/pages/bind/idcard/question/index.tsx'), loading: LoadingComponent}),
        "title": "实名认证"
      },
      {
        "path": "/bind",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__index' */'@/pages/bind/index.tsx'), loading: LoadingComponent}),
        "title": "认证绑定"
      },
      {
        "path": "/bind/phone",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bind__phone__index' */'@/pages/bind/phone/index.tsx'), loading: LoadingComponent}),
        "title": "手机号码换绑"
      },
      {
        "path": "/egzlogin/forget",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__egzlogin__forget__index' */'@/pages/egzlogin/forget/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/egzlogin",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__egzlogin__index' */'@/pages/egzlogin/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/face",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__face__index' */'@/pages/face/index.tsx'), loading: LoadingComponent}),
        "title": "人脸识别"
      },
      {
        "path": "/password",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__password__index' */'@/pages/password/index.tsx'), loading: LoadingComponent}),
        "title": "登录密码"
      },
      {
        "path": "/recode",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__recode__index' */'@/pages/recode/index.tsx'), loading: LoadingComponent}),
        "title": "登录记录"
      },
      {
        "path": "/sclogin/forget",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sclogin__forget__index' */'@/pages/sclogin/forget/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/sclogin",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sclogin__index' */'@/pages/sclogin/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/select/organization",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__select__organization__index' */'@/pages/select/organization/index.tsx'), loading: LoadingComponent}),
        "title": "选择机构"
      },
      {
        "path": "/select/seller-organization",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__select__seller-organization__index' */'@/pages/select/seller-organization/index.tsx'), loading: LoadingComponent}),
        "title": "选择机构"
      },
      {
        "path": "/teacher/:orderIdentity",
        "routes": [
          {
            "path": "/teacher/:orderIdentity/agreement",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teacher__orderIdentity__agreement__index' */'@/pages/teacher/[orderIdentity]/agreement/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/teacher/:orderIdentity/binding",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teacher__orderIdentity__binding__index' */'@/pages/teacher/[orderIdentity]/binding/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/teacher/:orderIdentity/forget",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teacher__orderIdentity__forget__index' */'@/pages/teacher/[orderIdentity]/forget/index.tsx'), loading: LoadingComponent}),
            "title": "忘记密码"
          },
          {
            "path": "/teacher/:orderIdentity/login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teacher__orderIdentity__login__index' */'@/pages/teacher/[orderIdentity]/login/index.tsx'), loading: LoadingComponent}),
            "title": "登录"
          },
          {
            "path": "/teacher/:orderIdentity/qrcode-login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teacher__orderIdentity__qrcode-login__index' */'@/pages/teacher/[orderIdentity]/qrcode-login/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/teacher/:orderIdentity/register",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teacher__orderIdentity__register__index' */'@/pages/teacher/[orderIdentity]/register/index.tsx'), loading: LoadingComponent})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teacher__orderIdentity___layout' */'@/pages/teacher/[orderIdentity]/_layout.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/seller'), loading: LoadingComponent})]
      },
      {
        "path": "/lock",
        "routes": [
          {
            "path": "/lock/person",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__lock__person__index' */'@/pages/lock/person/index.tsx'), loading: LoadingComponent})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__lock___layout' */'@/pages/lock/_layout.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/quick-login",
        "routes": [
          {
            "path": "/quick-login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__quick-login__index' */'@/pages/quick-login/index.tsx'), loading: LoadingComponent}),
            "title": "登录"
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__quick-login___layout' */'@/pages/quick-login/_layout.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/reset-pwd",
        "routes": [
          {
            "path": "/reset-pwd",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reset-pwd__index' */'@/pages/reset-pwd/index.tsx'), loading: LoadingComponent})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reset-pwd___layout' */'@/pages/reset-pwd/_layout.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/seller",
        "routes": [
          {
            "path": "/seller/forget",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seller__forget__index' */'@/pages/seller/forget/index.tsx'), loading: LoadingComponent}),
            "title": "忘记密码"
          },
          {
            "path": "/seller/login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seller__login__index' */'@/pages/seller/login/index.tsx'), loading: LoadingComponent}),
            "title": "登录"
          },
          {
            "path": "/seller/qrcode-login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seller__qrcode-login__index' */'@/pages/seller/qrcode-login/index.tsx'), loading: LoadingComponent}),
            "title": "登录-贵州题库系统"
          },
          {
            "path": "/seller/register",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seller__register__index' */'@/pages/seller/register/index.tsx'), loading: LoadingComponent}),
            "title": "注册"
          },
          {
            "path": "/seller/select-organization",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seller__select-organization__index' */'@/pages/seller/select-organization/index.tsx'), loading: LoadingComponent}),
            "title": "选择机构"
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seller___layout' */'@/pages/seller/_layout.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/seller'), loading: LoadingComponent})]
      },
      {
        "path": "/user",
        "routes": [
          {
            "path": "/user/agreement",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__agreement__index' */'@/pages/user/agreement/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/authmiddle",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__authmiddle__index' */'@/pages/user/authmiddle/index.tsx'), loading: LoadingComponent}),
            "title": "授权登录"
          },
          {
            "path": "/user/binding",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__binding__index' */'@/pages/user/binding/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/face-login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__face-login__index' */'@/pages/user/face-login/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/forget",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__forget__index' */'@/pages/user/forget/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login__index' */'@/pages/user/login/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/middle/qq",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__middle__qq__index' */'@/pages/user/middle/qq/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/middle/social-security",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__middle__social-security__index' */'@/pages/user/middle/social-security/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/qrcode",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__qrcode__index' */'@/pages/user/qrcode/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/register",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register__index' */'@/pages/user/register/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/user/:type/login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__type__login__index' */'@/pages/user/[type]/login/index.tsx'), loading: LoadingComponent})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user___layout' */'@/pages/user/_layout.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/user'), loading: LoadingComponent})]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
